@use 'hig-utils.scss' as utils;

@font-face {
    font-display: swap;
    font-weight: 400;
    font-style: normal;
    font-family: 'nvCustomIcons';
    src: url('nv-custom-icons.woff2') format('woff2');
}

/* icon added as it is from style file from icon library */

.icon-alert-circle_reg:before {
    content: '\e939';
}

.icon-notoptimized_sm:before {
    content: '\e93c';
}

.icon-optimized_reg:before {
    content: '\e93e';
}

.icon-optimized_sm:before {
    content: '\e93f';
}

.icon-share:before {
    content: '\e943';
}

.icon-onbattery:before {
    content: '\e902';
}

.icon-monitor:before {
    content: '\e901';
}

.icon-detailed_view:before {
    content: '\e932';
}

.icon-notoptimized:before {
    content: '\e92c';
}

.icon-chevron-down:before {
    content: '\e904';
}

.icon-chevron-up:before {
    content: '\e905';
}

.icon-settings:before {
    content: '\e917';
}

.icon-speedometer:before {
    content: '\e919';
}

.icon-view-module:before {
    content: '\e928';
}

.icon-gamestream:before {
    content: '\e945';
}

.icon-edit:before {
    content: '\e949';
}

.icon-gpu:before {
    content: '\e94d';
}

.icon-memory:before {
    content: '\e94f';
}

.icon-nvlogo:before {
    content: '\e951';
}

.icon-ram:before {
    content: '\e952';
}

.icon-highlight:before {
    content: '\e95f';
}

.icon-alert-bang:before {
    content: '\e950';
}

.icon-desktop-mac:before {
    content: '\e954';
}

.icon-ethernet:before {
    content: '\e955';
}

.icon-game-controller:before {
    content: '\e956';
}

.icon-game-library:before {
    content: '\e957';
}

.icon-isp:before {
    content: '\e958';
}

.icon-server:before {
    content: '\e959';
}

.icon-wifi:before {
    content: '\e95a';
}
