@use 'bidi-mixins.scss' as bidi;
@use '../../../../styles/colors.scss' as ui-colors;
@use 'hig-colors.scss' as hig-colors;

$mdc-checkbox-position: calc(-1 * (var(--mdc-checkbox-state-layer-size) - 18px) / 2);

.nv-hig {
    .mat-mdc-checkbox {
        display: block;

        // remove the after click persistent ripple
        &:not(.cdk-keyboard-focused) .mdc-checkbox__native-control:focus ~ .mdc-checkbox__ripple {
            --mdc-checkbox-unselected-focus-state-layer-opacity: 0;
            --mdc-checkbox-selected-focus-state-layer-opacity: 0;
        }
    }

    .mdc-checkbox {
        --mdc-checkbox-unselected-icon-color: #{hig-colors.$white-70p};
        --mdc-checkbox-disabled-selected-checkmark-color: #{ui-colors.$nv-gray20};
        @include bidi.left($mdc-checkbox-position);
    }

    .mdc-form-field label {
        @include bidi.margin-left(8px);
        line-height: 24px;
        @include bidi.left($mdc-checkbox-position);
        position: relative;
        align-self: center;
        white-space: normal;
        user-select: none;
    }

    .mdc-checkbox__background {
        border-radius: 0;
    }
}
