@use 'bidi-mixins.scss' as bidi;
@use './hig-typography-mixins.scss' as typography-mixins;
@use './hig-colors.scss' as colors;
@use './hig-media-queries.scss' as media-queries;

// snackbar styling
// this is required when the game is launched in portrait mode but user switches to Landscape after launch
.mat-mdc-snack-bar-handset {
    .nv-hig.mat-mdc-snack-bar-container {
        @include media-queries.breakpoint(iphone) {
            width: fit-content;
        }
        @include media-queries.breakpoint(iphone-landscape) {
            max-width: 70%;
            .mdc-snackbar__surface {
                min-width: 0;
            }
        }
    }
}

.cdk-overlay-pane:has(> .nv-hig.mat-mdc-snack-bar-container) {
    @include media-queries.breakpoint(iphone) {
        flex-grow: 1;
        @include bidi.margin-left(auto);
    }
    .nv-hig.mat-mdc-snack-bar-handset,
    .nv-hig.mat-mdc-snack-bar-container,
    .nv-hig.mat-mdc-snack-bar-label {
        flex-grow: 0;
    }
}

// required as the z-index of evidence-panel & snackbar is same with evidence-panel appearing later and thus on top
.cdk-global-overlay-wrapper:has(> .cdk-overlay-pane .nv-hig.mat-mdc-snack-bar-container) {
    z-index: 1001;
}

.nv-hig {
    // snackbar styling
    &.mat-mdc-snack-bar-container {
        --mdc-snackbar-container-color: colors.$gray700;
        background-color: colors.$gray700;
        border-radius: 0;
        margin: 24px;
        box-sizing: border-box;

        .mdc-snackbar__label {
            @include typography-mixins.font-body2();
        }

        .mdc-button__label {
            @include typography-mixins.font-button1();
        }

        .mdc-snackbar__surface {
            box-shadow: none;
        }

        @include media-queries.breakpoint(iphone) {
            @include bidi.margin(8px, 8px, 8px, calc(env(safe-area-inset-left) + 8px));
            max-width: 100%;
            min-width: 0;
            width: fit-content;
        }
        @include media-queries.breakpoint(iphone-landscape) {
            max-width: 70%;
            .mdc-snackbar__surface {
                min-width: 0;
            }
        }
    }

    .mat-mdc-snack-bar-action,
    .mat-mdc-snack-bar-action .mdc-button {
        min-width: 64px;
        min-height: 36px;
    }
}
