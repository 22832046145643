@use './hig-colors.scss' as colors;

.nv-hig {
    .mat-drawer {
        background-color: colors.$gray700;
    }

    .mat-drawer-backdrop.mat-drawer-shown {
        background-color: colors.$black-50p;
    }
}
