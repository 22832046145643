@use 'bidi-mixins.scss' as bidi;
.hig-radio-container {
    .hig-radio-option-label {
        @include bidi.padding-left(14px);
        display: flex;
        margin-top: 8px;
        margin-bottom: 8px;
        cursor: pointer;
        user-select: none;
    }
}
